import styles from './ProductSliderBlock.module.css'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

const ProductSliderBlock = ({ data, children, onInit, slideChanged }) => {

    return (
        <div className={`${styles.holder}` + (data.bgColor ? ` ${data.bgColor}` : '')} style={{ zIndex: data.zIndex }} id={data.id ? data.id : ''}>
            <i className={styles.shape}></i>
            <div className={styles.head}>
                <p className="text-l centered upper">{data.subtitle}</p>
                <p className={`title-xl centered upper black ${data.cutted ? styles.cutted : ''}`}>{data.title}</p>
            </div>
            <div className="container">
                <Swiper
                    modules={[Autoplay, EffectFade, Pagination]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={50}
                    slidesPerView={1}
                    effect="fade"
                    onSwiper={onInit}
                    onSlideChange={slideChanged}
                >
                    {children}
                </Swiper>
            </div>
        </div>
    );
}

export default ProductSliderBlock;