import styles from './VideReviews.module.css'
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';

import poster_1 from '../../img/videoreview_1.png'
import poster_2 from '../../img/videoreview_2.png'
import poster_3 from '../../img/videoreview_3.png'
import poster_4 from '../../img/videoreview_4.png'
import poster_5 from '../../img/videoreview_5.png'
import mountains from '../../img/before_footer.png'
import { Link, useLocation } from 'react-router-dom';

const VideoReviews = ({ isDesktop }) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const location = useLocation()

    return (
        <>
            <div className='container'>
                <div className={styles.holder}>
                    <p className={styles.title}>Отзывы<br />Клиентов</p>
                    <div className={styles.container}>
                        <Swiper
                            className={styles.swiper}
                            modules={[Autoplay, Navigation]}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.navigation.nextEl = navigationNextRef.current;
                                swiper.navigation.prevEl = navigationPrevRef.current;
                            }}
                            spaceBetween={32}
                            slidesPerView={isDesktop ? 5 : 1.5}
                            centeredSlides={isDesktop ? false : true}
                        >
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/01`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_1} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Светлана</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/02`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_2} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Егор</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/03`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_3} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Александр</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/04`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_4} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Тамара</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/05`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_5} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Вячеслав</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to={`/videoreview/06`}
                                    state={{ backgroundLocation: location }}
                                    className={styles.item}
                                >
                                    <figure className={styles.figure}>
                                        <img src={poster_1} alt='gallery' />
                                    </figure>
                                    <i className={styles.play}></i>
                                    <p className='text-m black upper'>Светлана</p>
                                    <p className='text-s text-grey'>Сеть магазинов «Чай и кофе»</p>
                                </Link>
                            </SwiperSlide>

                        </Swiper>
                        <div className={styles.button_prev} ref={navigationPrevRef}></div>
                        <div className={styles.button_next} ref={navigationNextRef}></div>
                    </div>
                </div>
            </div>
            <img src={mountains} className={styles.mountains} alt='mountains' />
        </>
    );
}

export default VideoReviews;