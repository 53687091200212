import styles from './Header.module.css'
import logo from '../../img/logo.png'
import { scrollTo } from '../../utils/functions';
import { useLayoutEffect, useState } from 'react';

const Header = ({ isDesktop }) => {
    const [menuOpened, setMenuOpened] = useState(false)
    const [scrolled, setScrolled] = useState(false)

    window.addEventListener('scroll', () => {
        window.scrollY > window.screen.height ? setScrolled(true) : setScrolled(false)
    })

    const handleClick = (target) => {
        scrollTo(target)
        setMenuOpened(false)
    }

    return (
        <>
            <div className={`${styles.mobile_menu} ${menuOpened ? 'opened' : ''} ${scrolled ? 'show' : ''}`}>
                <div className={styles.mobile_menu_buttons}>
                    <button className='button button-small button-black text-s upper' onClick={() => handleClick('footer')}>Кабинет B2B</button>
                    <button className='button button-small button-red text-s upper' onClick={() => handleClick('footer')}>Прайс-лист</button>
                </div>
                <div className={`${styles.mobile_opener} ${menuOpened ? 'opened' : ''}`} onClick={() => setMenuOpened(!menuOpened)}></div>
            </div>
            <div className={styles.header}>
                <div className={`${styles.logo} ${styles.mobile_logo}`}>
                    <img src={logo} alt="logo" />
                </div>
                {isDesktop && (
                    <div className={`${styles.header_inner}` + (menuOpened ? ` ${styles.opened}` : '')}>
                        <button className={styles.logo} onClick={() => scrollTo('top')}>
                            <img src={logo} alt="logo" />
                        </button>
                        <nav className={styles.nav}>
                            <button className={`text-s upper ${styles.link}`} onClick={() => scrollTo('services')}>Услуги</button>
                            <button className={`text-s upper ${styles.link}`} onClick={() => scrollTo('products')}>Продукция</button>
                            <button className={`text-s upper ${styles.link}`} onClick={() => scrollTo('team')}>Команда</button>
                            <button className={`text-s upper ${styles.link}`} onClick={() => scrollTo('production')}>Производство</button>
                            <button className={`text-s upper ${styles.link}`} onClick={() => scrollTo('contacts')}>Контакты</button>
                        </nav>
                        <div className={styles.buttons}>
                            <button className='button button-black text-s upper' onClick={() => scrollTo('footer')}>Кабинет B2B</button>
                            <button className='button button-red text-s upper' onClick={() => scrollTo('footer')}>Прайс-лист</button>
                        </div>
                    </div>
                )}
            </div>
            {!isDesktop && (
                <div className={`${styles.header_inner}` + (menuOpened ? ` ${styles.opened}` : '')}>
                    <button className={styles.logo} onClick={() => handleClick('top')}>
                        <img src={logo} alt="logo" />
                    </button>
                    <nav className={styles.nav}>
                        <button className={`text-s upper ${styles.link}`} onClick={() => handleClick('services')}>Услуги</button>
                        <button className={`text-s upper ${styles.link}`} onClick={() => handleClick('products')}>Продукция</button>
                        <button className={`text-s upper ${styles.link}`} onClick={() => handleClick('team')}>Команда</button>
                        <button className={`text-s upper ${styles.link}`} onClick={() => handleClick('production')}>Производство</button>
                        <button className={`text-s upper ${styles.link}`} onClick={() => handleClick('contacts')}>Контакты</button>
                    </nav>
                    <div className={styles.buttons}>
                        <button className='button button-black text-s upper' onClick={() => scrollTo('footer')}>Кабинет B2B</button>
                        <button className='button button-red text-s upper' onClick={() => scrollTo('footer')}>Прайс-лист</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Header;
