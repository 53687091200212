import styles from './TitleScreen.module.css'
import layer_1 from '../../img/title_screen_layer_1.png'
import layer_2 from '../../img/title_screen_layer_2.png'
import layer_3 from '../../img/title_screen_layer_3.png'
import { useState } from 'react';
import { useScroll } from 'react-ui-animate';
import { Parallax } from 'react-scroll-parallax';

const TitleScreen = () => {
    const [scroll, setScroll] = useState(0)

    useScroll(({ scrollY }) => {
        setScroll(scrollY)
    })

    return (
        <div className={styles.title_screen} id='top'>
            {/* <Parallax
                speed={-50}
                translateY={[500, 500]}
            > */}
            <article
                className={styles.article}
                style={{ marginTop: `${scroll * 0.7 > 360 ? 360 : scroll * 0.7}px` }}
            >
                <p className={`${styles.text} text-l upper centered`}>Для бизнеса и дома</p>
                <p className={`${styles.text} title-xl upper black centered`}>Легенда <br /> чая</p>
            </article>
            {/* </Parallax> */}
            {/* <Parallax speed={-10}> */}
            <img
                className={styles.layer_1}
                src={layer_1}
                style={{ marginTop: `${scroll * -0.1}px` }}
                alt='layer'
            />
            {/* </Parallax>
            <Parallax speed={-50}> */}
            <img
                className={styles.layer_2}
                src={layer_2}
                style={{ marginTop: (window.screen.width > 550 ? `${scroll * -0.5 < -200 ? -200 : scroll * -0.5}px` : `${scroll * -0.5 < -136 ? -136 : scroll * -0.5}px`) }}
                alt='layer'
            />
            {/* </Parallax>
            <Parallax speed={-50}> */}
            <img
                className={styles.layer_3}
                src={layer_3}
                style={{ marginTop: `${scroll * -1.2}px` }}
                alt='layer'
            />
            {/* </Parallax> */}
        </div>
    );
}

export default TitleScreen;
