import { SwiperSlide } from "swiper/react";
import ProductSliderBlock from "./ProductSliderBlock";
import styles from './ProductSliderBlock.module.css'
import image_4 from '../../img/slider_product_image_4.png'
import background_4 from '../../img/slider_product_bg_4.png'
import mobile_image_4 from '../../img/slider_product_mobile_image_4.png'
import ym from '../../img/icon-yandex-market.svg'
import ozon from '../../img/icon-ozon.svg'
import wb from '../../img/icon-wb.png'
import { Parallax } from "react-scroll-parallax";

const ProductSliderBlock4 = ({ isDesktop }) => {

    return (
        <ProductSliderBlock data={{ bgColor: styles.red, subtitle: `С вас логотип - \n с нас готовый продукт`, title: 'Ваш бренд', zIndex: 4, cutted: true, id: false }}>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_4} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20} style={{ zIndex: 2 }}>
                            <img src={background_4} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_4} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <ul className="text-xl dash" style={{ gap: '24px' }}>
                        <li>«Индивидуальные купажи для моего <br />чая и кофе?»</li>
                        <li> «Легко!»</li>
                    </ul>
                    <div className={styles.price_block}>
                        <p className={`text-xl ${styles.price}`}>за <span className='title-l'><b>3 дня</b></span></p>
                        <p className='text-m'>Согласование <br /> и запуск бренда</p>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.marketplaces}>
                        <img src={ym} alt="yandex market" />
                        <img src={ozon} alt="ozon" />
                        <img src={wb} alt="wildberries" />
                    </div>
                    <p className='text-l upper thin'>Контент поддержка <br /> на маркетплейсах</p>
                    <ul className="dash">
                        <li className="text-m thin upper">Этикетки</li>
                        <li className="text-m thin upper">Фотографии</li>
                        <li className="text-m thin upper">Описание</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
        </ProductSliderBlock>
    );
}

export default ProductSliderBlock4;