import { useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import './App.css';

import Header from '../Header/Header';
import { MainPage } from '../../pages';
import Modal from '../Modal/Modal';

const App = () => {
  const [desktop, setDesktop] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  let state = location.state

  useLayoutEffect(() => {
    if (window.screen.width <= 767) {
      setDesktop(false)
    } else {
      setDesktop(true)
    }

    window.addEventListener('resize', () => {
      if (window.screen.width <= 767) {
        setDesktop(false)
      } else {
        setDesktop(true)
      }
    });
  }, [])

  const closeModal = () => {
    navigate('/')
  }

  return (
    <>
      <Header isDesktop={desktop} />
      <main className='main'>
        <>
          <Routes location={state?.backgroundLocation || location}>
            <Route path='/' element={<MainPage isDesktop={desktop} />} />
            <Route path='*' element={<MainPage isDesktop={desktop} />} />
            <Route path='/videoreview/:id' element={
              <Modal onClose={closeModal} />
            } />
          </Routes>
          {state?.backgroundLocation && (
            <Routes>
              <Route path='/videoreview/:id' element={
                <Modal onClose={closeModal} />
              } />
            </Routes>
          )}
        </>
      </main>
    </>
  );
}

export default App;
