import { useEffect } from 'react';
import styles from './Modal.module.css'
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';

const modalRoot = document.getElementById("modal");

const videos = [
    {
        id: '01',
        text: 'Первое видео'
    },
    {
        id: '02',
        text: 'Второе видео'
    },
    {
        id: '03',
        text: 'Третье видео'
    },
    {
        id: '04',
        text: 'Четвертое видео'
    },
    {
        id: '05',
        text: 'Пятое видео'
    },
    {
        id: '06',
        text: 'Шестое видео'
    },
]

const Modal = ({ onClose }) => {

    let id = useParams().id

    const data = videos.filter((item) => item.id === id)[0]

    useEffect(() => {
        const escFunction = (e) => {
            e.key === "Escape" && onClose()
        }

        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    }, [onClose])

    return createPortal(
        <div className={styles.modal_overlay} onClick={onClose}>
            <div className={styles.closer} onClick={onClose}></div>
            <div className={styles.modal} onClick={e => e.stopPropagation()}>
                <p style={{ color: '#FFFFFF' }}>{data.text}</p>
            </div>
        </div>,
        modalRoot
    );
}

export default Modal;