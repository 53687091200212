import Marquee from 'react-fast-marquee';
import { YMaps, Map, Placemark } from '@r3flector/react-yandex-maps';
// import Lottie from 'react-lottie-player'
// import lottieJson from '../../img/123123.json'

import { ReactComponent as InfoImage1 } from '../../img/info_1.svg'
import { ReactComponent as InfoImage2 } from '../../img/info_2.svg'
import { ReactComponent as Qr } from '../../img/qr.svg'

import styles from './Footer.module.css'
import { useEffect, useMemo, useRef, useState } from 'react';
import Loader from "react-js-loader";
import { IMaskInput } from 'react-imask';

const phoneMask = "+{7} (000) 000-00-00"
const emailMask = /^\S*@?\S*$/

const Footer = ({ isDesktop }) => {
    const [loading, setLoading] = useState(true)
    const formRef = useRef()
    const nameRef = useRef()
    const cityRef = useRef()
    const phoneRef = useRef()
    const emailRef = useRef()
    const [submit, setSubmit] = useState(false)
    const [inputFields, setInputFields] = useState({
        name: '',
        city: '',
        phone: '',
        email: ''
    })

    useEffect(() => {
        if (inputFields.name !== '' &&
            inputFields.city !== '' &&
            inputFields.phone.length >= 18 &&
            /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(inputFields.email)) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    }, [inputFields])

    const accessDeny = (e) => {
        e.preventDefault()

        if (inputFields.name === '') {
            nameRef.current.closest('label').classList.add('empty')

            setTimeout(() => {
                nameRef.current.closest('label').classList.remove('empty')
            }, 500);
        }

        if (inputFields.city === '') {
            cityRef.current.closest('label').classList.add('empty')

            setTimeout(() => {
                cityRef.current.closest('label').classList.remove('empty')
            }, 500);
        }

        if (inputFields.phone.length < 18) {
            phoneRef.current.element.closest('label').classList.add('empty')

            setTimeout(() => {
                phoneRef.current.element.closest('label').classList.remove('empty')
            }, 500);
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(inputFields.email)) {
            emailRef.current.element.closest('label').classList.add('empty')

            setTimeout(() => {
                emailRef.current.element.closest('label').classList.remove('empty')
            }, 500);
        }

        if (inputFields.name === '') {
            nameRef.current.focus()
        } else if (inputFields.city === '') {
            cityRef.current.focus()
        } else if (inputFields.phone.length < 18) {
            phoneRef.current.element.focus()
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(inputFields.email)) {
            emailRef.current.element.focus()
        }
    }

    const accessAllow = (e) => {
        e.preventDefault()
        // fetch
    }

    const handleClick = (e) => {
        if (inputFields.name === '' ||
            inputFields.city === '' ||
            inputFields.phone.length < 18 ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(inputFields.email)) {
            accessDeny(e)
        } else {
            accessAllow(e)
        }
    }

    const mapState = useMemo(
        () => ({
            center: [44.024799, 43.128943],
            zoom: 17
        }),
        []
    );


    return (
        <footer className={styles.footer} id='contacts'>
            {/* <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 150, height: 150 }}
            /> */}
            <Marquee className={`title-xl black upper text-white ${styles.marquee}`} speed={100}>
                <p>Давайте знакомиться</p>
            </Marquee>
            <div className='container'>
                <div className={styles.footer_inner}>
                    <div className={styles.col}>
                        <p className='title-s text-grey-light'>Подписывайтесь</p>
                        <div className={styles.buttons}>
                            <a href='/' className='button button-bordered-blue text-xl upper black'>Vk.com</a>
                            <a href='/' className='button button-bordered-lazurite text-xl upper black'>Telegram</a>
                        </div>
                        <p className='title-s text-grey-light'>Оптовый склад</p>
                        <article className={styles.article}>
                            <p className='text-l text-grey-light'>Р-217 Кавказ, 375-й километр, 7Г <br /> Телефон: <a href="tel:88007007887">8 800 700-78-87</a> <br /> Эл.почта: <a href="mailto:zakaz@legend-tea.ru">zakaz@legend-tea.ru</a> <br /> Время работы: Вт - Вск: 08:00 - 16:00</p>
                            <div className={styles.map_holder}>
                                {loading &&
                                    <div className={styles.loader_holder}>
                                        <Loader
                                            type="spinner-default"
                                            bgColor={'#D33836'}
                                        />
                                    </div>
                                }
                                <YMaps query={{ apikey: '672b4a8e-0905-4d43-afa3-0f4d16050e23', lang: 'ru_RU' }}>
                                    <Map className={styles.map}
                                        defaultState={mapState}
                                        onLoad={() => setLoading(false)}
                                    >
                                        <Placemark
                                            geometry={[44.024799, 43.128943]}
                                            options={{
                                                iconColor: '#D33836'
                                            }}
                                        />
                                    </Map>
                                </YMaps>
                            </div>


                        </article>
                    </div>
                    <div className={styles.col} id='footer'>
                        <form
                            className={styles.form}
                            ref={formRef}
                        >
                            <label className={styles.label}>
                                <small className={styles.placeholder}>Имя</small>
                                <input
                                    ref={nameRef}
                                    type="text"
                                    name="name"
                                    id="NAME"
                                    placeholder='Имя'
                                    required
                                    value={inputFields.name}
                                    onChange={e => setInputFields({ ...inputFields, [e.target.name]: e.target.value })}
                                />
                            </label>
                            <label className={styles.label}>
                                <small className={styles.placeholder}>Город</small>
                                <input
                                    ref={cityRef}
                                    type="text"
                                    name="city"
                                    id="CITY"
                                    placeholder='Город'
                                    required
                                    value={inputFields.city}
                                    onChange={e => setInputFields({ ...inputFields, [e.target.name]: e.target.value })}
                                />
                            </label>
                            <label className={styles.label}>
                                <small className={styles.placeholder}>Телефон</small>
                                <IMaskInput
                                    ref={phoneRef}
                                    mask={phoneMask}
                                    name='phone'
                                    id='PHONE'
                                    placeholder='Телефон'
                                    minLength={18}
                                    required
                                    value={inputFields.phone}
                                    onChange={e => setInputFields({ ...inputFields, [e.target.name]: e.target.value })}
                                />
                            </label>
                            <label className={styles.label}>
                                <small className={styles.placeholder}>Электронаня почта</small>
                                <IMaskInput
                                    type='email'
                                    ref={emailRef}
                                    mask={emailMask}
                                    name='email'
                                    id='EMAIL'
                                    placeholder='Электронаня почта'
                                    required
                                    value={inputFields.email}
                                    onChange={e => setInputFields({ ...inputFields, [e.target.name]: e.target.value })}
                                />
                            </label>
                            <div className={styles.info}>
                                <div className={styles.info_block}>
                                    <InfoImage1 alt="icon" />
                                    <p className='text-m text-grey-light'>Вышлем прайс в PDF</p>
                                </div>
                                <div className={styles.info_block}>
                                    <InfoImage2 alt="icon" />
                                    <p className='text-m text-grey-light'>Вышлем доступ к кабинету</p>
                                </div>
                            </div>
                            {isDesktop ? (
                                <button
                                    type="submit"
                                    className={`button button-red text-m upper ${styles.button} ${submit ? 'allow' : ''}`}
                                    onClick={handleClick}
                                // ref={buttonRef}
                                >Получить прайс и доступ в кабинет</button>
                            ) : (
                                <button
                                    type="submit"
                                    className={`button button-red text-m upper ${styles.button} ${submit ? 'allow' : ''}`}
                                    onClick={handleClick}
                                // ref={mobileButtonRef}
                                >Получить прайс</button>
                            )}

                            <small className='text-s text-grey-dark'>Нажимая кнопку «Получить прайс», я даю свое согласие на <a href='/'>обработку моих персональных данных</a></small>
                        </form>
                    </div>
                </div>
            </div>
            <div className={styles.footer_after}>
                <div className="container">
                    <div className={styles.footer_after_grid}>
                        <nav className={styles.links_map}>
                            <a className='text-m text-grey-light' href='/'>Работа у нас</a>
                            <a className='text-m text-grey-light' href='/'>Интернет-магазин</a>
                            <a className='text-m text-grey-light' href='/'>Оптовый кабинет</a>
                            <a className='text-m text-grey-light' href='/'>Контакты</a>
                        </nav>
                        <nav className={styles.qrs}>
                            <a className={styles.qr_code} href='/'>
                                <figure>
                                    <Qr alt="qr code" />
                                </figure>
                                <p className='text-s upper text-grey-light'>Вконтакте</p>
                            </a>
                            <a className={styles.qr_code} href='/'>
                                <figure>
                                    <Qr alt="qr code" />
                                </figure>
                                <p className='text-s upper text-grey-light'>Телеграм</p>
                            </a>
                            <a className={styles.qr_code} href='/'>
                                <figure>
                                    <Qr alt="qr code" />
                                </figure>
                                <p className='text-s upper text-grey-light'>Карта</p>
                            </a>
                            <a className={styles.qr_code} href='/'>
                                <figure>
                                    <Qr alt="qr code" />
                                </figure>
                                <p className='text-s upper text-grey-light'>Сайт</p>
                            </a>
                        </nav>
                    </div>
                    <p className={styles.last_text}>Легенда чая</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;