import { SwiperSlide } from "swiper/react";
import ProductSliderBlock from "./ProductSliderBlock";
import styles from './ProductSliderBlock.module.css'
import image_2 from '../../img/slider_product_image_2.png'
import background_2 from '../../img/slider_product_bg_2.png'
import mobile_image_2 from '../../img/slider_product_mobile_image_2.png'
import { Parallax } from "react-scroll-parallax";
import { useState } from "react";

const ProductSliderBlock2 = ({ isDesktop }) => {
    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0)

    const slideTo = (index) => {
        setActiveSlide(index)
        swiper.slideTo(index)
    }

    const slideChanged = () => {
        setActiveSlide(swiper.realIndex)
    }

    return (
        <ProductSliderBlock
            data={{
                bgColor: styles.black,
                subtitle: 'Продукция',
                title: 'Чай 700+ видов',
                zIndex: 2,
                cutted: true
            }}
            onInit={setSwiper}
            slideChanged={slideChanged}
        >
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_2} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_2} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_2} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Сибирская <br /> ягода</span></p>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>195</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={`pagination_2 ${styles.right_column}`} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Черный </li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Зеленый</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Белый</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Ройбуш</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>С добавками</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Фруктово-ягодный</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Травы</li>
                    </ul>
                    <p className='text-m'>Создадим индивидуальный купаж под ваш запрос!</p>
                </Parallax>
            </SwiperSlide>
        </ProductSliderBlock>
    );
}

export default ProductSliderBlock2;