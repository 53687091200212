import Marquee from 'react-fast-marquee';
import styles from './Productiom.module.css'
import eco from '../../img/eco.svg'
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay } from 'swiper/modules';
import prod_1 from '../../img/production_1.png'
import prod_2 from '../../img/production_2.png'
import prod_3 from '../../img/production_3.png'

const Production = () => {
    return (
        <section className={styles.holder} id='production'>
            <Marquee className={`title-xl black upper text-white ${styles.marquee}`} speed={100}>
                <p>Производство</p>
                <img src={eco} alt='decor' draggable={false} />
            </Marquee>
            <div className={styles.overflow}>
                <div className={styles.container}>
                    <Swiper
                        className={styles.swiper}
                        // modules={[Autoplay]}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        spaceBetween={64}
                        slidesPerView={'auto'}
                    >
                        <SwiperSlide className={styles.swiper_slide}>
                            <p className={styles.swiper_title}>ростер <br /> Loring S15 Falcon</p>
                            <img src={prod_1} alt='slide' />
                            <p className='text-s'>Позволяет обжаривать небольшие партии кофе: как для эспрессо, так и для фильтра. Тем самым, создавая неповторимые и уникальные купажи для тех, кому важно предлагать своим клиентам только лучшее.</p>
                            <p className={styles.text_index}>01</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper_slide}>
                            <p className={styles.swiper_title}>Семпл <br /> ростер <br /> Roest</p>
                            <img src={prod_2} alt='slide' />
                            <p className='text-s'>Самый совершенный сэмпл-ростер в мире из Норвегии с загрузкой до 100 грамм кофе. На нем мы обжариваем все образцы из новых поступлений кофе, чтобы определить их качество и вкус перед промышленной обжаркой</p>
                            <p className={styles.text_index}>02</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper_slide}>
                            <p className={styles.swiper_title}>Фото- <br /> сепаратор <br /> Сапсан</p>
                            <img src={prod_3} alt='slide' />
                            <p className='text-s'>Колорсортер необходим для фильтрации качественного зерна от бракованного. С помощью фотосепаратора Сапсан мы убираем квакеры из общей массы обжаренного зерна, тем самым улучшая вкусовые характеристики кофе.</p>
                            <p className={styles.text_index}>03</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </section>
    );
}

export default Production;