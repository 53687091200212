import { SwiperSlide } from "swiper/react";
import ProductSliderBlock from "./ProductSliderBlock";
import styles from './ProductSliderBlock.module.css'
import image_3 from '../../img/slider_product_image_3.png'
import background_3 from '../../img/slider_product_bg_3.png'
import mobile_image_3 from '../../img/slider_product_mobile_image_3.png'
import { Parallax } from "react-scroll-parallax";
import { useState } from "react";

const ProductSliderBlock3 = ({ isDesktop }) => {
    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0)

    const slideTo = (index) => {
        setActiveSlide(index)
        swiper.slideTo(index)
    }

    const slideChanged = () => {
        setActiveSlide(swiper.realIndex)
    }

    return (
        <ProductSliderBlock
            data={{
                bgColor: styles.green,
                subtitle: 'Добавки к чаю',
                title: 'Ингредиенты',
                zIndex: 3,
                cutted: false
            }}
            onInit={setSwiper}
            slideChanged={slideChanged}
        >
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_3} alt="banner" />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_3} alt="background" />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_3} alt="mobile" />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <div className={styles.price_block}>
                        <p className={`title-m black ${styles.name}`}><span>Бутон <br /> Розовая роза <br /> Сушеный</span></p>
                        {/* <p className='text-m' style={{ maxWidth: '220px' }}>Помогает улучшить память, зрение и интуицию</p> */}
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>39</b> <i className="ruble" /></span></p>
                        <p className='text-l black upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>
                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className={`text-l upper ${activeSlide === 0 ? 'active' : ''}`} onClick={() => slideTo(0)}>Цветы</li>
                        <li className={`text-l upper ${activeSlide === 1 ? 'active' : ''}`} onClick={() => slideTo(1)}>Травы</li>
                        <li className={`text-l upper ${activeSlide === 2 ? 'active' : ''}`} onClick={() => slideTo(2)}>Специи</li>
                        <li className={`text-l upper ${activeSlide === 3 ? 'active' : ''}`} onClick={() => slideTo(3)}>Орехи</li>
                        <li className={`text-l upper ${activeSlide === 4 ? 'active' : ''}`} onClick={() => slideTo(4)}>Цукаты</li>
                        <li className={`text-l upper ${activeSlide === 5 ? 'active' : ''}`} onClick={() => slideTo(5)}>Сублимация</li>
                        <li className={`text-l upper ${activeSlide === 6 ? 'active' : ''}`} onClick={() => slideTo(6)}>Сушеные</li>
                        <li className={`text-l upper ${activeSlide === 7 ? 'active' : ''}`} onClick={() => slideTo(7)}>Плоды</li>
                    </ul>
                </Parallax>
            </SwiperSlide>
        </ProductSliderBlock>
    );
}

export default ProductSliderBlock3;