import TitleScreen from "../../components/TitleScreen/TitleScreen";
import ServicesBlock from '../../components/ServicesBlock/ServicesBlock';
import ProductSliderBlock1 from '../../components/ProductSliderBlock/ProductSliderBlock_1';
import ProductSliderBlock2 from '../../components/ProductSliderBlock/ProductSliderBlock_2';
import ProductSliderBlock3 from '../../components/ProductSliderBlock/ProductSliderBlock_3';
import ProductSliderBlock4 from '../../components/ProductSliderBlock/ProductSliderBlock_4';
import Team from '../../components/Team/Team';
import Production from '../../components/Production/Production';
import VideoReviews from '../../components/VIdeoReviews/VideoReviews';
import Footer from '../../components/Footer/Footer';

const MainPage = ({ isDesktop }) => {
    return (
        <>
            <TitleScreen />
            <div className='container'>
                <ServicesBlock />
            </div>
            <ProductSliderBlock1 isDesktop={isDesktop} />
            <ProductSliderBlock2 isDesktop={isDesktop} />
            <ProductSliderBlock3 isDesktop={isDesktop} />
            <ProductSliderBlock4 isDesktop={isDesktop} />
            <div className='container'>
                <Team />
            </div>
            <Production />
            <VideoReviews isDesktop={isDesktop} />
            <Footer isDesktop={isDesktop} />
        </>
    );
}

export default MainPage;