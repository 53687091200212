import styles from './ServicesBlock.module.css'
import icon_1500 from '../../img/1500.svg'
import pek from '../../img/pek.svg'
import bg_pek from '../../img/bg_pek.svg'
import bg_marketplaces from '../../img/bg_marketplaces.svg'
import icon_timer from '../../img/icon-timer.svg'
import icon_lock from '../../img/icon-lock.svg'
import video_poster_1 from '../../img/video_poster_1.png'
import { useState } from 'react'
import { useScroll } from 'react-ui-animate'
import { scrollTo } from '../../utils/functions'

const ServicesBlock = () => {
    const [scroll, setScroll] = useState(0)

    useScroll(({ scrollY }) => {
        setScroll(scrollY)
    })

    let space = window.screen.width > 550 ? 200 : 120

    window.addEventListener('resize', () => {
        space = window.screen.width > 550 ? 200 : 120
    })

    return (
        <section className={styles.services_holder} id='services' style={{ transform: `translateY(${space - scroll * 0.7 <= 0 ? 0 : space - scroll * 0.7}px)` }}>
            <div className={`${styles.block_1} ${styles.sand}`}>
                <img src={icon_1500} className={styles.img} alt='icon' />
                <article className={styles.big_article}>
                    <p className={`${styles.title} title-m`}>Для бизнеса</p>
                    <ul className={styles.badges}>
                        <li className='text-s upper'>Чай 600+</li>
                        <li className='text-s upper'>Кофе 180+</li>
                        <li className='text-s upper'>Зеленый кофе 50+</li>
                        <li className='text-s upper'>Горячий шоколад</li>
                        <li className='text-s upper'>Какао</li>
                        <li className='text-s upper'>Сладости</li>
                        <li className='text-s upper'>Добавки к чаю</li>
                        <li className='text-s upper'>Упаковка</li>
                        <li className='text-s upper'>Посуда</li>
                    </ul>
                    <div className={styles.buttons}>
                        <a href='#b2b' className="button button-black text-m upper" onClick={() => scrollTo('footer')}>Кабинет B2B</a>
                        <a href='#priceList' className="button button-red text-m upper" onClick={() => scrollTo('footer')}>Прайс-лист</a>
                    </div>
                </article>
            </div>
            <a href='/' className={`${styles.block_2}`}>
                <video poster={video_poster_1} autoPlay muted loop playsInline className={styles.video}>
                    <source src='' type='video/mp4' />
                </video>
                <article className={styles.big_article}>
                    <p className="title-m text-white">Для дома</p>
                    <p className="text-m text-white">Перейти в розничный интернет-магазин</p>
                </article>
            </a>
            <div className={`${styles.block_middle} ${styles.dark_blue}`}>
                <img src={bg_pek} alt="pek" />
                <article className={styles.middle_article}>
                    <img src={pek} alt="background" />
                    <p className="text-m text-white">Отгрузка на следующий день после оплаты</p>
                </article>
            </div>
            <div className={`${styles.block_middle} ${styles.dark}`}>
                <img src={bg_marketplaces} alt="marketplaces" />
                <article className={styles.middle_article}>
                    <p className="text-m text-white">Полная поддержка на маркетплейсах</p>
                </article>
            </div>
            <div className={`${styles.block_small} ${styles.dark_green}`}>
                <div className={styles.icon}>
                    <img src={icon_timer} alt="icon" />
                </div>
                <p className="text-m text-white centered">Ваш бренд <br /> за 3 дня</p>
            </div>
            <div className={`${styles.block_small} ${styles.orange}`}>
                <div className={styles.icon}>
                    <img src={icon_lock} alt="icon" />
                </div>
                <p className="text-m text-white centered">Оптовый <br /> кабинет 24/7</p>
            </div>
        </section>
    );
}

export default ServicesBlock;