import { SwiperSlide } from "swiper/react";
import ProductSliderBlock from "./ProductSliderBlock";
import styles from './ProductSliderBlock.module.css'
import image_1 from '../../img/slider_product_image_1.png'
import background_1 from '../../img/slider_product_bg_1.png'
import mobile_image_1 from '../../img/slider_product_mobile_image_1.png'
import { Parallax } from "react-scroll-parallax";

const ProductSliderBlock1 = ({ isDesktop }) => {

    return (
        <ProductSliderBlock data={{ bgColor: styles.white, subtitle: 'Продукция', title: 'Кофе Montis', zIndex: 1, cutted: true, id: 'products' }}>
            <SwiperSlide className={styles.swiper_slide}>
                {isDesktop ? (
                    <>
                        <Parallax className={styles.image} speed={-10}>
                            <img src={image_1} alt='banner' />
                        </Parallax>
                        <Parallax className={styles.background_image} speed={20}>
                            <img src={background_1} alt='background' />
                        </Parallax>
                    </>
                ) : (
                    <Parallax className={styles.mobile_image} speed={5}>
                        <img src={mobile_image_1} alt='mobile' />
                    </Parallax>
                )}
                <Parallax className={styles.left_column} speed={isDesktop ? 30 : 0}>
                    <ul className={styles.ul}>
                        <li className='text-l upper'>Arabica</li>
                        <li className='text-l upper'>Robusta</li>
                        <li className='text-l upper'>Blend</li>
                        <li className='text-l upper'>Premium</li>
                        <li className='text-l upper'>Decaf</li>
                        <li className='text-l upper'>Aroma</li>
                    </ul>
                    <div className={styles.price_block}>
                        <p className={`text-xl ${styles.price}`}>от <span className='title-l'><b>395</b> <i className="ruble" /></span></p>
                        <p className='text-l black text-red upper'>Купить <br /> в розницу</p>
                        <a className='text-m upper link' href="/">оптовый <br /> прайс</a>
                    </div>
                </Parallax>

                <Parallax className={styles.right_column} speed={isDesktop ? 30 : 0}>
                    <p className={`text-m ${styles.text_icon}`}>Обжаривается кофе на самом современном в мире ростере Loring</p>
                </Parallax>
            </SwiperSlide>
        </ProductSliderBlock>
    );
}

export default ProductSliderBlock1;