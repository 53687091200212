import styles from './Team.module.css'
import img_1 from '../../img/meta_1.svg'
import img_2 from '../../img/meta_2.svg'
import img_3 from '../../img/meta_3.svg'
import team_1 from '../../img/team_1.png'
import team_2 from '../../img/team_2.png'
import team_3 from '../../img/team_3.png'

const Team = () => {
    return (
        <section className={styles.holder} id='team'>
            <ul className={styles.meta}>
                <li>
                    <img src={img_1} alt='icon' />
                    <p className='text-l'>Оптовый кабинет <br /> с доступом 24/7</p>
                </li>
                <li>
                    <img src={img_2} alt='icon' />
                    <p className='text-l'>Оформи до 13:00 и <br /> заказ уйдет сегодня</p>
                </li>
                <li>
                    <img src={img_3} alt='icon' />
                    <p className='text-l'>Контент-поддержка <br /> оптовых клиентов</p>
                </li>
            </ul>
            <p className={`${styles.text} title-xm upper`}>Мы улучшаем качество нашего сервиса и помогаем клиентам продавать больше и быстрее, <span>а не просто производим чай или кофе</span></p>
            <ul className={styles.team}>
                <li>
                    <img src={team_1} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванов Иван Иванович</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_2} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_3} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_1} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванов Иван Иванович</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_2} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_3} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_1} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванов Иван Иванович</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_2} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_3} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванова Елизавета Константиновна</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
                <li>
                    <img src={team_1} alt="avatar" draggable={false} />
                    <p className='text-m upper black'>Иванов Иван Иванович</p>
                    <p className='text-s text-grey'>Должность</p>
                </li>
            </ul>
        </section>
    );
}

export default Team;